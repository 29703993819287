/**=====================
    4.5 Home Page scss
==========================**/

.home-section {
    text-align: center;
    padding: calc(100px + (190 - 100) * ((100vw - 320px) / (1920 - 320))) 0
        calc(40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    background-image: url(../images/home/home-bg.png);
    background-size: cover;
    z-index: 1;
    @media (max-width: 991px) {
        overflow: hidden;
    }
    .bg-effect {
        .bg-gif {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 55%;
            z-index: -1;
            top: 0;
            opacity: 0.04;
            mix-blend-mode: lighten;
        }
        .effect1 {
            position: absolute;
            left: 7%;
            top: 24%;
            @media (max-width: 1199px) {
                left: 1%;
            }
            @media (max-width: 767px) {
                left: -10px;
            }
            @media (max-width: 650px) {
                display: none;
            }
        }
        .effect2 {
            position: absolute;
            right: 8%;
            bottom: 28%;
            @media (max-width: 1199px) {
                right: 5%;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    .home-content {
        h1 {
            color: rgba(var(--title-color), 1);
            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
            .title-effect {
                padding: 3px 20px;
                background-color: #17273f;
                display: inline-block;
                position: relative;
                margin: 0 15px;
                @media (max-width: 767px) {
                    padding: 1px 10px;
                }
                @media (max-width: 576px) {
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                }
                img {
                    position: absolute;
                    left: -18px;
                    top: -18px;
                    height: calc(100% + 36px);
                    width: calc(100% + 36px);
                    @media (max-width: 576px) {
                        display: none;
                    }
                }
                span {
                    background: url("../images/title-bg.jpg");
                    background-position: bottom;
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
        }
        p {
            width: 70%;
            margin: 0 auto;
            margin: 32px auto;
            @media (max-width: 1199px) {
                width: 80%;
                margin: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320))) auto
                    calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            }
            @media (max-width: 991px) {
                width: 100%;
            }
        }
        .start-link {
            display: flex;
            color: rgba(var(--theme-color), 1);
            font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            transition: all 0.5s ease;
            width: max-content;
            margin: 0 auto;
            &:hover {
                transform: translateY(-5px) scale(1.1);
                transition: all 0.5s ease;
            }
            svg {
                stroke: rgba(var(--theme-color), 1);
            }
        }
    }
    .home-laptop {
        margin-top: 48px;
        .laptop-sec {
            perspective: 1130px;
        }
        .laptop-img {
            transform-style: preserve-3d;
            vertical-align: middle;
            transform: rotateX(50deg);
            will-change: transform;
        }
        .hand-sec {
            @media (max-width: 767px) {
                display: none;
            }
            .left-hand {
                position: absolute;
                bottom: calc(-100% + 108px);
                left: 215px;
                @media (max-width: 1430px) {
                    left: 188px;
                    bottom: calc(-100% + 99px);
                }
                @media (max-width: 1400px) {
                    left: 97px;
                    bottom: calc(-100% + 97px);
                }
                @media (max-width: 1199px) {
                    left: 10px;
                    bottom: calc(-100% + 98px);
                }
                @media (max-width: 991px) {
                    left: -105px;
                }
            }
            .right-hand {
                position: absolute;
                bottom: calc(-100% + 108px);
                right: 215px;
                transform: scaleX(-1);
                @media (max-width: 1430px) {
                    right: 188px;
                    bottom: calc(-100% + 99px);
                }
                @media (max-width: 1400px) {
                    right: 97px;
                    bottom: calc(-100% + 97px);
                }
                @media (max-width: 1199px) {
                    right: 10px;
                    bottom: calc(-100% + 98px);
                }
                @media (max-width: 991px) {
                    right: -105px;
                }
            }
            .left-finger {
                position: absolute;
                bottom: -8px;
                left: 305px;
                z-index: 1;
                @media (max-width: 1430px) {
                    left: 292px;
                }
                @media (max-width: 1400px) {
                    left: 202px;
                }
                @media (max-width: 1199px) {
                    left: 115px;
                }
                @media (max-width: 991px) {
                    left: 0;
                }
            }
            .right-finger {
                position: absolute;
                bottom: -8px;
                right: 305px;
                z-index: 1;
                transform: scaleX(-1);
                @media (max-width: 1430px) {
                    right: 292px;
                }
                @media (max-width: 1400px) {
                    right: 202px;
                }
                @media (max-width: 1199px) {
                    right: 115px;
                }
                @media (max-width: 991px) {
                    right: 0;
                }
            }
        }
        .home-info {
            margin-top: 75px;
            @media (max-width: 767px) {
                margin-top: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320)));
            }
            .info-list {
                display: flex;
                align-items: center;
                gap: 2px 25px;
                justify-content: center;
                padding-bottom: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
                position: relative;
                flex-wrap: wrap;
                @media (max-width: 1400px) {
                    gap: 2px 15px;
                }
                &:after {
                    content: "";
                    width: 32px;
                    height: 2px;
                    background-color: rgba(var(--theme-color), 1);
                    border-radius: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                li {
                    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(var(--theme-color), 1);
                    padding-left: 15px;
                    position: relative;
                    @media (max-width: 1400px) {
                        padding-left: 8px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background-color: rgba(var(--theme-color), 1);
                        border-radius: 100%;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .star-rating {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3px;
                color: #ffb800;
                margin-top: 8px;
                i {
                    font-size: 18px;
                }
            }
            h4 {
                color: rgba(var(--title-color), 1);
                max-width: 27%;
                margin: 4px auto 0;
                line-height: 1.4;
                @media (max-width: 991px) {
                    max-width: 48%;
                }
                @media (max-width: 767px) {
                    max-width: 58%;
                }
                @media (max-width: 576px) {
                    max-width: 90%;
                }
                @media (max-width: 360px) {
                    max-width: 100%;
                }
            }
        }
    }
}

.service-section {
    padding-top: 178px;
    background: linear-gradient(180.02deg, rgba(var(--title-color), 0.05) 0.02%, rgba(var(--title-color), 0) 90.86%);
    position: relative;
    overflow: hidden;
    @media (max-width: 991px) {
        padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    &:after {
        content: "";
        position: absolute;
        background-image: url("../images/service-bg.png");
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.65;
        background-size: cover;
    }
    .service-box {
        padding: 40px;
        background: #0a0e17;
        position: relative;
        border-radius: 4px;
        height: 100%;
        @media (max-width: 1400px) {
            padding: 30px;
        }
        @media (max-width: 1199px) {
            padding: 20px;
        }
        @media (max-width: 576px) {
            padding: 14px;
        }
        &:after {
            content: "";
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(
                315.14deg,
                rgba(var(--theme-color), 0) 65%,
                rgba(var(--theme-color), 0.15) 83.43%
            );
            border-radius: 4px;
            z-index: -1;
            transition: all 0.5s ease;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                148deg,
                rgba(var(--theme-color), 0.15) -15.73%,
                rgba(var(--theme-color), 0) 98.72%
            );
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease;
        }
        .service-icon {
            width: 72px;
            height: 72px;
            background: rgba(var(--title-color), 0.05);
            border-radius: 4px;
            position: relative;
            transition: all 0.5s ease;
            @media (max-width: 1400px) {
                width: 62px;
                height: 62px;
            }
            @media (max-width: 576px) {
                width: 52px;
                height: 52px;
            }
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .outline-icon {
                transition: all 0.5s ease;
            }
            .bold-icon {
                opacity: 0;
                visibility: hidden;
                transform: translate(-50%, -50%) rotate(180deg);
                transition: all 0.5s ease;
            }
        }
        .service-content {
            margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
            h3 {
                color: rgba(var(--white), 1);
                text-transform: capitalize;
                display: flex;
                align-items: center;
                gap: 10px;
            }
            p {
                margin-bottom: 0;
            }
        }
        &:hover {
            &:after {
                background: rgba(var(--theme-color), 0.25) !important;
            }
            &:before {
                opacity: 1;
                visibility: visible;
            }
            .service-icon {
                background-color: rgba(var(--theme-color), 0.1);
                .outline-icon {
                    transform: translate(-50%, -50%) rotate(-180deg);
                    opacity: 0;
                    visibility: hidden;
                }
                .bold-icon {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    .service-row {
        > div {
            &:nth-child(2) {
                .service-box {
                    &:after {
                        background: linear-gradient(
                            45deg,
                            rgba(var(--theme-color), 0) 65%,
                            rgba(var(--theme-color), 0.15) 83.43%
                        );
                    }
                }
            }
            &:nth-child(3) {
                .service-box {
                    &:after {
                        background: linear-gradient(
                            225deg,
                            rgba(var(--theme-color), 0) 65%,
                            rgba(var(--theme-color), 0.15) 83.43%
                        );
                    }
                }
            }
            &:nth-child(4) {
                .service-box {
                    &:after {
                        background: linear-gradient(
                            135deg,
                            rgba(var(--theme-color), 0) 65%,
                            rgba(var(--theme-color), 0.15) 83.43%
                        );
                    }
                }
            }
        }
    }
    .service-info {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 32px;
        @media (max-width: 1199px) {
            padding-left: 0;
        }
        p {
            margin-bottom: 0;
        }
        a {
            margin-top: 35px;
            @media (max-width: 991px) {
                margin-top: 25px;
            }
        }
    }
}

.about-section {
    position: relative;
    overflow: hidden;

    .about-content {
        padding-left: 80px;
        [dir="rtl"] & {
            padding-left: 0;
            padding-right: 80px;
        }
        @media (max-width: 1199px) {
            padding-left: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
            [dir="rtl"] & {
                padding-left: 0;
                padding-right: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
        }
        p {
            margin-bottom: 30px;
            @media (max-width: 1199px) {
                margin-bottom: 20px;
            }
        }
        li {
            color: rgba(var(--white), 1);
            display: flex;
            align-items: center;
            gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
            font-size: 18px;
            margin-bottom: 22px;
            img {
                width: 25px;
                @media (max-width: 1199px) {
                    width: 18px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: 1199px) {
                font-size: 16px;
            }
        }
    }
    .about-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        img {
            @media (max-width: 991px) {
                width: 58%;
            }
        }
    }
    .bg-effect {
        background: rgba(var(--title-color), 0.08);
        position: absolute;
        height: 100%;
        width: calc(100% - calc(27px + (264 - 27) * ((100vw - 1430px) / (1920 - 1430))));
        right: 0;
        top: 0;
        z-index: -1;
        [dir="rtl"] & {
            right: unset;
            left: 0;
        }
        @media (max-width: 1429px) {
            width: calc(100% - calc(52px + (67 - 52) * ((100vw - 1400px) / (1430 - 1400))));
        }
        @media (max-width: 1399px) {
            width: calc(100% - calc(42px + (142 - 42) * ((100vw - 1200px) / (1400 - 1200))));
        }
        @media (max-width: 1199px) {
            width: calc(100% - calc(29px + (137 - 29) * ((100vw - 992px) / (1200 - 992))));
        }
        @media (max-width: 991px) {
            width: calc(100% - calc(36px + (148 - 36) * ((100vw - 768px) / (992 - 768))));
        }
        @media (max-width: 767px) {
            width: calc(100% - calc(33px + (127 - 33) * ((100vw - 576px) / (768 - 576))));
        }
        @media (max-width: 576px) {
            width: calc(100% - 15px);
        }
    }
    &.right-version {
        .about-content {
            padding-left: 0;
            padding-right: 80px;
            [dir="rtl"] & {
                padding-left: 80px;
                padding-right: 0;
            }
            @media (max-width: 1199px) {
                padding-right: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
                [dir="rtl"] & {
                    padding-right: 0;
                    padding-left: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }
        .bg-effect {
            left: 0;
            right: unset;
            [dir="rtl"] & {
                left: unset;
                right: 0;
            }
        }
    }
}

.feature-section {
    position: relative;
    z-index: 1;
    padding-bottom: calc(80px + (230 - 80) * ((100vw - 320px) / (1920 - 320)));

    .feature-left {
        position: absolute;
        left: 0;
        width: 50%;
        top: 0;
        z-index: -1;
        mix-blend-mode: difference;
        opacity: 0.12;
        transform: rotate(180deg);
    }
    .feature-right {
        position: absolute;
        right: 0;
        width: 50%;
        top: 0;
        z-index: -1;
        mix-blend-mode: difference;
        opacity: 0.12;
        transform: rotate(180deg);
    }
    .feature-bg {
        position: absolute;
        bottom: 0;
        opacity: 0.2;
        width: 100%;
        left: 0;
    }
    .round-effect {
        position: absolute;
        width: 309px;
        height: 309px;
        background: #196ed3;
        -webkit-filter: blur(422px);
        filter: blur(422px);
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        border-radius: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        z-index: -1;
    }
    .feature-box {
        background-color: #0a0e17;
        padding: 34px;
        border-radius: 4px;
        position: relative;
        height: 100%;
        [dir="rtl"] & {
            direction: rtl;
        }
        @media (max-width: 1400px) {
            padding: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        &:after {
            content: "";
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(199.52deg, rgba(var(--theme-color), 0.22) 7.05%, rgba(10, 14, 23, 0) 134.77%);
            border-radius: 4px;
            z-index: -1;
            transition: all 0.5s ease;
        }
        .feature-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            h3 {
                margin-bottom: 0;
                color: rgba(var(--white), 1);
                position: relative;
                padding-left: 30px;
                [dir="rtl"] & {
                    padding-left: 0;
                    padding-right: 30px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    height: 30px;
                    width: 1px;
                    background-color: rgba(var(--theme-color), 1);
                    left: 15px;
                    [dir="rtl"] & {
                        left: unset;
                        right: 15px;
                    }
                }
            }
            .feature-icon {
                width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                position: relative;
                transition: all 0.5s ease;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                .outline-icon {
                    transition: all 0.5s ease;
                }
                .bold-icon {
                    opacity: 0;
                    visibility: hidden;
                    transform: translate(-50%, -50%) scaleX(-1);
                    transition: all 0.5s ease;
                }
            }
        }
        h4 {
            color: rgba(var(--content-color), 1);
            margin-bottom: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.3;
        }
        .link-overflow {
            width: 100%;
            overflow: hidden;
        }
        a {
            font-size: 16px;
            display: flex;
            align-content: center;
            transition: all 0.5s ease;
            transform: translateX(-112px);
            transition: all 0.5s ease;
            @media (max-width: 991px) {
                transform: none;
            }
            [dir="rtl"] & {
                transform: translateX(112px);
                @media (max-width: 991px) {
                    transform: none;
                }
            }
            svg {
                fill: rgba(var(--theme-color), 1);
                margin-left: 34px;
                transition: all 0.5s ease;
                @media (max-width: 991px) {
                    margin-left: 15px;
                }
                [dir="rtl"] & {
                    margin-left: 0;
                    margin-right: 34px;
                    @media (max-width: 991px) {
                        margin-right: 15px;
                    }
                }
            }
        }
        &:hover {
            &:after {
                background: linear-gradient(314deg, rgba(var(--theme-color), 0.22) 7.05%, rgba(10, 14, 23, 0) 134.77%);
                transition: all 0.5s ease;
            }
            .feature-icon {
                .outline-icon {
                    transform: translate(-50%, -50%) scaleX(-1);
                    opacity: 0;
                    visibility: hidden;
                }
                .bold-icon {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(-50%, -50%);
                }
            }
            a {
                transform: translateX(0);
                svg {
                    margin-left: 15px;
                    [dir="rtl"] & {
                        margin-left: 0;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .swiper-wrapper {
        padding: 1px;
    }
    .pagination-effect {
        width: 12.8%;
        position: absolute;
        bottom: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
        height: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        display: flex;
        align-items: center;
        img {
            position: absolute;
            display: block;
            left: 0;
            width: calc(26px + (43 - 26) * ((100vw - 320px) / (1920 - 320)));
            height: calc(26px + (43 - 26) * ((100vw - 320px) / (1920 - 320)));
            transition: all 300ms ease;
        }
    }
    .swiper-pagination {
        width: 12.8%;
        height: 10px;
        background: #0d1a36;
        border: 1px solid rgba(var(--white), 0.03);
        box-shadow: inset 0px 2px 4px rgba(var(--title-color), 0.06);
        border-radius: 20px;
        top: unset;
        bottom: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
        left: 50%;
        transform: translateX(-50%);
        overflow: visible;
        z-index: 0;
        .swiper-pagination-progressbar-fill {
            background: rgba(var(--theme-color), 1);
            border-radius: 20px;
        }
    }
}

.pricing-section {
    overflow: hidden;
    .pricing-box {
        position: relative;
        .handing-cls {
            right: -12px;
            position: absolute;
            top: 200px;
        }
        .pricing-top {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 24px 40px;
            background: linear-gradient(0deg, rgb(10, 14, 23), rgb(10, 14, 23)),
                linear-gradient(179.9deg, rgb(10, 91, 117) -47.93%, rgb(2, 17, 26) 98.07%);
            position: relative;
            width: max-content;
            margin: 0 auto;
            z-index: 1;
            position: relative;
            .pricing-bg {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
            }
            h3 {
                color: rgba(var(--title-color), 1);
                margin-bottom: 0;
            }
        }
        .pricing-mid {
            background-color: #f5f5f54d;
            clip-path: polygon(7.8% 0%, 91.1% 0, 100% 100%, 0% 100%);
            padding: 1px;
            margin-top: -11px;
            z-index: 1;
            position: relative;
            .clip-path-content {
                padding: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
                clip-path: polygon(8% 0, 91% 0, 100% 100%, 0% 100%);
                border: none;
                background: linear-gradient(180deg, rgba(var(--title-color), 0) -778.43%, #1d2029 79.61%);
                @media (max-width: 1199px) {
                    padding: 15px;
                }
            }
            h2,
            h4 {
                color: rgba(var(--white), 1);
            }
            h4 {
                margin-bottom: 0;
            }
        }
        .pricing-content {
            width: 90%;
            margin: 0 auto;
            background-color: rgba(var(--title-color), 0.3);
            padding: 0 1px 1px 1px;
            clip-path: polygon(100% 0, 100% 100%, 49% 92%, 0 100%, 0 0);
            .clip-path-content {
                background: #1d212a;
                clip-path: polygon(100% 0, 100% 100%, 49% 92%, 0 100%, 0 0);
                padding: 44px 40px 60px;
                @media (max-width: 1400px) {
                    padding: 24px 30px 60px;
                }
                @media (max-width: 1199px) {
                    padding: 24px 20px calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
                }
            }
            li {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: calc(22px + (32 - 22) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(var(--white), 1);
                h4 {
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .pricing-link {
                color: rgba(var(--white), 1);
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                text-align: center;
                text-transform: uppercase;
                padding-top: 30px;
                display: block;
                border-top: 1px dashed rgba(var(--white), 0.3);
                margin-top: 20px;
                @media (max-width: 1199px) {
                    padding-top: 20px;
                }
            }
        }
    }
    .pricing-row {
        align-items: flex-end;
        > div {
            &:nth-child(2) {
                .pricing-box {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 44px;
                        height: 44px;
                        left: 50%;
                        bottom: 25px;
                        background: rgba(var(--theme-color), 1);
                        box-shadow: 0px 5px 30px rgba(var(--theme-color), 0.4);
                        transform: translateX(-50%) rotate(45deg);
                        z-index: -1;
                    }
                    .pricing-mid {
                        background-color: rgba(var(--theme-color), 0.3);
                        .clip-path-content {
                            background: linear-gradient(182.67deg, rgba(var(--theme-color), 0) -468.82%, #0c191f 90.76%),
                                linear-gradient(0deg, rgba(var(--theme-color), 0.3), rgba(var(--theme-color), 0.3));
                        }
                        h2 {
                            color: rgba(var(--theme-color), 1);
                        }
                    }
                    .pricing-content {
                        background: repeating-linear-gradient(
                            45deg,
                            rgba(var(--theme-color), 0.5) 0px,
                            rgba(var(--theme-color), 0.5) 2px,
                            transparent 2px,
                            transparent 9px
                        );
                        .clip-path-content {
                            margin-top: -2px;
                            background: linear-gradient(0deg, #0c191f, #0c191f),
                                linear-gradient(0deg, rgba(var(--theme-color), 0.3), rgba(var(--theme-color), 0.3));
                        }
                        .pricing-link {
                            color: rgba(var(--theme-color), 1);
                        }
                    }
                }
            }
        }
    }
}

.testimonial-section {
    background-image: url("../images/testimonial-bg.png");
    background-size: cover;
    position: relative;
    .swiper {
        padding: 0 1px 45px;
        .swiper-pagination {
            bottom: -6px;
            .swiper-pagination-bullet {
                background: rgba(110, 113, 121, 0.4);
                opacity: 1;
                &-active {
                    background: rgba(var(--theme-color), 1);
                }
            }
        }
    }
    .testimonial-box {
        [dir="rtl"] & {
            direction: rtl;
        }
        .content-sec {
            display: flex;
            align-items: flex-start;
            gap: 22px;
            padding: 30px;
            border: 1px solid rgba(var(--title-color), 0.23);
            border-radius: 4px;
            background-color: #0a0e17;
            position: relative;
            @media (max-width: 1400px) {
                padding: 20px;
            }
            @media (max-width: 767px) {
                padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
            }
            p {
                margin-bottom: 0;
                width: calc(100% - 30px - 22px);
            }
            .quote-img {
                img {
                    width: 30px;
                }
                .fill-img {
                    display: none;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: 8px;
                top: 8px;
                background-color: rgba(var(--theme-color), 1);
                border-radius: 6px;
                width: 100%;
                height: 100%;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
            }
        }
        .small-circle,
        .large-circle {
            width: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
            height: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 100%;
            padding: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: radial-gradient(47.62% 47.62% at 33.33% 28.57%, #acb0bc 0%, #6e7179 100%);
            margin: 10px auto 0;
            span {
                background-color: #131d2b;
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 100%;
            }
        }
        .large-circle {
            width: calc(20px + (23 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (23 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        .avtar-img {
            width: 110px;
            height: 110px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
            margin: 10px auto 0;
            @media (max-width: 1400px) {
                width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
                height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
            }
        }
        h4 {
            color: rgba(var(--title-color), 1);
            text-align: center;
            margin-top: 10px;
        }
        h5 {
            color: rgba(var(--content-color), 1);
            margin-bottom: 0;
            text-align: center;
        }
    }
    .swiper-slide-active {
        padding-top: 60px;
        @media (max-width: 1400px) {
            padding-top: 40px;
        }
        @media (max-width: 1199px) {
            padding-top: 0;
        }
        @media (min-width: 1199px) {
            .testimonial-box {
                .content-sec {
                    border: 1px solid rgba(var(--title-color), 0.1);
                    &:before {
                        opacity: 1;
                        visibility: visible;
                    }
                    .quote-img {
                        .fill-img {
                            display: block;
                        }
                        .outline-img {
                            display: none;
                        }
                    }
                }
                .small-circle {
                    margin: 18px auto 0;
                }
                .small-circle,
                .large-circle {
                    background: rgba(var(--theme-color), 1);
                    span {
                        background: rgba(var(--theme-color), 1);
                    }
                }
                .avtar-img {
                    border: 5px solid rgba(var(--theme-color), 1);
                }
                h4 {
                    color: rgba(var(--theme-color), 1);
                }
            }
        }
    }
}

.faq-section {
    padding-bottom: calc(0px + (60 - 0) * ((100vw - 320px) / (1920 - 320)));
    .accordion {
        .accordion-item {
            margin-bottom: 25px;
            background: transparent;
            border: 1px solid rgba(var(--title-color), 0.1);
            border-radius: 4px;
            .accordion-button {
                background-color: rgba(#f5f5f5, 0.04);
                box-shadow: none;
                color: rgba(var(--theme-color), 1);
                font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
                padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
                    calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1.4;
                &:after {
                    content: "\f1af";
                    font-family: remixicon !important;
                    font-style: normal;
                    background-image: none;
                }
                &.collapsed {
                    color: rgba(var(--white), 1);
                    &:after {
                        content: "\ea13";
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            .accordion-body {
                color: rgba(var(--content-color), 1);
                padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
                    calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                border-top: 1px solid rgba(var(--title-color), 0.1);
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.info-section {
    position: relative;
    overflow: hidden;
    .hand-effect {
        .left-hand {
            position: absolute;
            left: -260px;
            bottom: -78px;
            z-index: 1;
        }
        .right-hand {
            position: absolute;
            right: -260px;
            top: -78px;
            z-index: 1;
            transform: scale(-1);
        }
    }
    .info-box {
        text-align: center;
        background-image: url("../images/info-bg.jpg");
        background-size: cover;
        border-radius: 8px;
        padding: 50px;
        border: 1px solid rgba(var(--theme-color), 0.3);
        position: relative;
        @media (max-width: 1199px) {
            padding: 40px;
        }
        @media (max-width: 991px) {
            padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) 10px;
        }
        h2 {
            color: rgba(var(--theme-color), 1);
            margin-bottom: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
            text-transform: uppercase;
            font-weight: 600;
            margin-top: calc(-4px + (-10 - -4) * ((100vw - 320px) / (1920 - 320)));
            span {
                position: relative;
                color: rgba(var(--white), 1);
                img {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -20px;
                }
            }
        }
        p {
            width: 60%;
            margin: 0 auto 15px;
            color: rgba(var(--content-color), 1);
            @media (max-width: 1400px) {
                width: 70%;
            }
            @media (max-width: 1199px) {
                width: 80%;
            }
            @media (max-width: 991px) {
                width: 90%;
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 40px;
            justify-content: center;
            margin-bottom: 40px;
            @media (max-width: 1400px) {
                gap: 20px;
                margin-bottom: 30px;
            }
            @media (max-width: 991px) {
                gap: 10px;
                margin-bottom: 20px;
            }
            li {
                display: flex;
                align-items: center;
                gap: 15px;
                color: rgba(var(--white), 1);
                font-size: 18px;
                img {
                    @media (max-width: 1199px) {
                        width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
                @media (max-width: 1199px) {
                    font-size: 16px;
                    gap: 8px;
                }
            }
        }
        a {
            margin: 0 auto;
        }
    }
}
