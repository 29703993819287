/*=====================
    2.1 Animation Scss start
==========================*/
@keyframes flip {
    from {
        transform: scaleX(-1);
    }
    to {
        transform: none;
    }
}
