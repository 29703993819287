/**=====================
   4.3 Contact Page scss
==========================**/

.contact-section {
     .contact-box {
          text-align: center;
          gap: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          @media (max-width: 991px) {
               gap: 40px;
          }
          li {
               @media (max-width: 991px) {
                    width: calc(50% - 50px);
               }
               @media (max-width: 430px) {
                    width: 100%;
               }
          }
          .contact-icon {
               width: calc(67px + (77 - 67) * ((100vw - 320px) / (1920 - 320)));
               height: calc(67px + (77 - 67) * ((100vw - 320px) / (1920 - 320)));
               padding: 15px;
               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               background: rgba(var(--title-color), 0.08);
               border: 1px solid rgba(var(--title-color), 0.3);
               margin: 0 auto 15px;
          }
          h3 {
               color: rgba(var(--theme-color), 1);
               margin-bottom: 10px;
          }
          h4 {
               margin-bottom: 0;
               color: rgba(var(--content-color), 1);
               line-height: 1.3;
          }
     }
     .contact-details {
          background: rgba(var(--title-color), 0.06);
          border: 1px solid rgba(var(--title-color), 0.3);
          padding: 50px;
          margin-top: 38px;
          @media (max-width: 1199px) {
               padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
          }
          .map-sec {
               height: 100%;
               position: relative;
               @media (max-width: 991px) {
                    height: 210px;
               }
               iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
               }
          }
     }
}
