/*=====================
  1.2 Typography CSS start
==========================*/

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: $outfit;
  background: linear-gradient(0deg, #0a0e17, #0a0e17), linear-gradient(179.9deg, #0a5b75 -47.93%, #02111a 98.07%);
  &:after {
    content: "";
    position: absolute;
    background-image: url(../images/about-bg.png);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.65;
    background-attachment: fixed;
    background-size: cover;
  }
  &.inner-bg {
    &:after {
      background-image: url(../images/inner-bg.png);
    }
  }
}

h1 {
  font-weight: 600;
  font-size: calc(22px + (56 - 22) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.5;
  letter-spacing: 0.03em;
}

h2 {
  font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

h3 {
  font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
}

h4 {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
}

h5 {
  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  color: rgba(var(--content-color), 1);
  font-weight: 400;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: rgba(var(--theme-color), 1);
}

a {
  text-decoration: none;
  color: rgba(var(--theme-color), 1);
  &:hover {
    color: rgba(var(--theme-color), 1);
  }
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  [dir="rtl"] & {
    padding-inline-start: 0;
    margin-inline-start: 0;
  }
}

li {
  list-style: none;
  display: inline-block;
}
