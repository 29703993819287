/*=====================
    2.6 form Scss start
==========================*/
.input-group {
    > .form-control,
    > .form-floating,
    > .form-select {
        &:focus,
        &:focus-within {
            box-shadow: none;
            z-index: unset;
        }
    }
}

.auth-form {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    .form-label {
        color: rgba(var(--title-color), 1);
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .form-control {
        background: rgba(var(--title-color), 0.1);
        border-radius: 4px;
        border: none;
        padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)))
            calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        box-shadow: none;
        outline: none;
        color: rgba(var(--content-color), 1);
    }
    .form-group {
        position: relative;
        .form-control {
            padding-left: 70px;
        }
        .iconsax {
            position: absolute;
            left: 14px;
            bottom: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
            border-right: 1px solid rgba(var(--content-color), 1);
            padding-right: 14px;
            svg [stroke] {
                stroke: rgba(var(--content-color), 1);
            }
            svg [fill] {
                fill: rgba(var(--content-color), 1);
            }
        }
    }
}

.divider {
    margin-top: 25px;
    text-align: center;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(var(--content-color), 1);
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
    h3 {
        color: rgba(var(--content-color), 1);
        margin-bottom: 0;
        display: inline-block;
        background-color: #0e242f;
        padding: 0 12px;
    }
}
