/*=====================
   1.0 Variables start
==========================*/

// font family
$outfit: "Outfit", sans-serif;

// icon font family
$remixicon: remixicon !important;

:root {
    --black: 0, 0, 0;
    --white: 255, 255, 255;
    --title-color: 245, 245, 245;
    --content-color: 156, 157, 159;
    --theme-color: 59, 237, 178;
    --dark-title: 13, 18, 31;
    --Iconsax-Color: #6e7179;
    --secondary-color: 244, 137, 65;
    --warning-color: 255, 189, 48;
    --info-color: 220, 154, 254;
    --danger-color: 157, 145, 255;
}
