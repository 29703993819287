/**=====================
    4.4 Error Page scss
==========================**/
.error-section {
    position: relative;
    padding-top: 230px;
    @media (max-width: 1199px) {
        padding-top: 160px;
    }
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 25px;
        background-image: url("../svg/404/404-background.svg");
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: -1;
    }
    .error-main {
        .error-img {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                @media (max-width: 767px) {
                    width: 25%;
                }
            }
            .robot-img {
                animation: error 2s linear;
                margin: 0 -30px -40px;
                z-index: 1;
                @media (max-width: 767px) {
                    width: 46%;
                }
                @media (max-width: 576px) {
                    width: 56%;
                }
            }
        }
        .error-content {
            text-align: center;
            margin-top: 65px;
            h2 {
                color: rgba(var(--theme-color), 1);
                font-size: calc(26px + (34 - 26) * ((100vw - 320px) / (1920 - 320)));
            }
            p {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(var(--content-color), 1);
            }
        }
    }
}

@keyframes error {
    0% {
        transform: translateY(-280px) rotate(5deg);
    }
    50% {
        transform: translateY(-160px) rotate(-35deg);
    }
    100% {
        transform: translateY(0) rotate(0);
    }
}
