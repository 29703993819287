/*=====================
    2.3 Button Scss start
==========================*/

.btn {
    font-size: 22px;
    color: rgba(var(--theme-color), 1);
    font-weight: 500;
}

.btn-theme {
    position: relative;
    display: flex;
    text-align: center;
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    background: rgba(var(--theme-color), 0.21);
    backdrop-filter: blur(6px);
    border-radius: 8px;
    padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)))
        calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    span {
        margin: auto;
        color: rgba(var(--theme-color), 1);
    }
    &:after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        background: linear-gradient(114.72deg, rgba(62, 255, 255, 0.5) -16.82%, rgba(58, 237, 177, 0.5) 59.74%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, /* [1] */ linear-gradient(#fff 0 0); /* [2] */
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        border: 1px solid;
        border-image-source: linear-gradient(
            114.72deg,
            rgba(62, 255, 255, 0.5) -16.82%,
            rgba(58, 237, 177, 0.5) 59.74%
        );
    }

    &:hover {
        background: rgba(var(--theme-color), 0.21);
        box-shadow: 0 0 17px 3px rgba(52, 208, 156, 0.34);
    }
}

.btn-arrow {
    color: rgba(var(--theme-color), 1);
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 18px;
    width: max-content;
    .icon-arrow {
        width: calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320)));
        height: calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320)));
        background-color: rgba(var(--theme-color), 1);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #0f141d;
        position: relative;
        transition: all 0.5s ease;
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(var(--theme-color), 0.15);
            border-radius: 100%;
            right: -8px;
            transition: all 0.5s ease;
        }
        svg {
            stroke: rgba(var(--dark-title), 1);
            fill: rgba(var(--dark-title), 1);
            transform: rotate(45deg);
            width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            transition: all 0.5s ease;
        }
    }
    &:hover {
        color: rgba(var(--theme-color), 1);
        .icon-arrow {
            transform: translateX(5px);
            &:after {
                right: 0;
            }
            svg {
                transform: rotate(90deg);
            }
        }
    }
}

.btn-solid {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--dark-title), 1);
    background-color: rgba(var(--theme-color), 1);
    border: 1px solid rgba(var(--theme-color), 1);
    display: inline-block;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
        calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    width: max-content;
    transition: all 0.5s ease;
    &:hover {
        background-color: transparent;
        color: rgba(var(--theme-color), 1);
    }
}
